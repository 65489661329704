#toggle {
  display: none;
}

.hamburger {
  z-index: 10;
  position: absolute;
  top: 20px;
  right: 30px;
}

.hamburger div {
  position: relative;
  width: 35px;
  height: 2px;
  background-color: white;
  margin-top: 9px;
  transition: all 0.3s ease-in-out;
}

.nav {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #5a02a0;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
}
.nav-wrapper {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 122px;
  margin-bottom: 44px;
  height: calc(100% - 122px - 44px);
}

/**
Animations
**/
#toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 25px;
}
#toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}
#toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -4px;
}

#toggle:checked + .hamburger + .nav {
  top: 0;
  transform: scale(1);
}
