html {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #5a02a0 0vh, #c205ff 100vh);
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

input {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

textarea {
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

* {
  font-family: 'Roboto', sans-serif;
  /* Hide scrollbar for Chrome, Safari and Opera */
  :-webkit-scrollbar {
    display: none;
  }

/* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.loader {
  border: 10px solid #5a02a0;
  border-top: 10px solid #c205ff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  align-self: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
